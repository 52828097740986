import Vue from 'vue'
import Num2persian from 'num2persian'
import {priceFormat} from "@/prototypes/u/other";


function convertNumberToEnglishLetter (initialNumber) {
  // System for American Numbering
  const th_val = ['', 'thousand', 'million', 'billion', 'trillion'];
  // System for uncomment this line for Number of English
  // var th_val = ['','thousand','million', 'milliard','billion'];

  const dg_val = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  const tn_val = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
  const tw_val = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  initialNumber = priceFormat(initialNumber.toString());
  initialNumber = initialNumber.replace(/[\, ]/g, '');
  if (initialNumber != parseFloat(initialNumber))
    return 'not a number ';
  let x_val = initialNumber.indexOf('.');
  if (x_val == -1)
    x_val = initialNumber.length;
  if (x_val > 15)
    return 'too big';
  let n_val = initialNumber.split('');
  let convertedNumber = '';
  let sk_val = 0;
  for (let i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 == 2) {
      if (n_val[i] == '1') {
        convertedNumber += tn_val[Number(n_val[i + 1])] + ' ';
        i++;
        sk_val = 1;
      } else if (n_val[i] != 0) {
        convertedNumber += tw_val[n_val[i] - 2] + ' ';
        sk_val = 1;
      }
    } else if (n_val[i] != 0) {
      convertedNumber += dg_val[n_val[i]] + ' ';
      if ((x_val - i) % 3 == 0)
        convertedNumber += 'hundred ';
      sk_val = 1;
    }
    if ((x_val - i) % 3 == 1) {
      if (sk_val)
        convertedNumber += th_val[(x_val - i - 1) / 3] + ' ';
      sk_val = 0;
    }
  }
  if (x_val != initialNumber.length) {
    let y_val = initialNumber.length;
    convertedNumber += 'point ';
    for (let i = x_val + 1; i < y_val; i++)
      convertedNumber += dg_val[n_val[i]] + ' ';
  }
  return convertedNumber.replace(/\s+/g, ' ');
}

export function numberToWord (number) {
  if (Vue.prototype.i18n.locale === 'fa') {
    return Num2persian(number)
  } else {
    return convertNumberToEnglishLetter(number)
  }
}
