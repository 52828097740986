// =============================================================================
// SERVICE PAGES LAYOUTS
// =============================================================================

export default {
  path: '/services',
    component: () => import('../../views/admin/services/index'),
  children: [
  {
    path: '',
    component: () => import('../../views/admin/services/servicesList/index'),
    children: [
      {
        path: '',
        name: 'ServicesList',
        component: () => import(/* webpackChunkName: "ServicesList" */ '../../views/admin/services/servicesList/list/servicesList.vue'),
        meta: {
          pageTitle: 'لیست خدمت',
          pageTitleI18n: 'services.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'ServicesTrash',
        component: () => import(/* webpackChunkName: "ServicesTrash" */ '../../views/admin/services/servicesList/trash/servicesTrash.vue'),
        meta: {
          pageTitle: 'سطل آشغال خدمت',
          pageTitleI18n: 'services.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: 'service-:id',
        component: () => import('../../views/admin/services/servicesList/service/index.vue'),
        children: [
          {
            path: '/',
            name: 'Service',
            component: () => import(/* webpackChunkName: "Service" */ '../../views/admin/services/servicesList/service/menu/serviceMenu.vue'),
            meta: {
              pageTitle: 'خدمت',
              pageTitleI18n: 'services.service.title'
              // rule: 'editor'
            }
          },
          // {
          //   path: 'attributes',
          //   name: 'ProductAttributes',
          //   component: () => import(/* webpackChunkName: "ProductAttributes" */ '../../views/admin/services/servicesList/service/attributes/productAttributes.vue'),
          //   meta: {
          //     pageTitle: 'ویژگی های خدمت',
          //     pageTitleI18n: 'services.service.attributes.title'
          //     // rule: 'editor'
          //   }
          // },
          // {
          //   path: 'turnover',
          //   name: 'ProductTurnover',
          //   component: () => import(/* webpackChunkName: "ProductTurnover" */ '../../views/admin/products/productsList/product/turnover/productSaleTurnover.vue'),
          //   meta: {
          //     pageTitle: 'گردش انبار کالا',
          //     pageTitleI18n: 'products.product.turnover.sale.title'
          //     // rule: 'editor'
          //   }
          // },
          // {
          //   path: 'variants',
          //   name: 'ProductVariants',
          //   component: () => import(/* webpackChunkName: "ProductVariants" */ '../../views/admin/products/productsList/product/variants/productVariants.vue'),
          //   meta: {
          //     pageTitle: 'متغیر های کالا',
          //     pageTitleI18n: 'products.product.variants.title'
          //     // rule: 'editor'
          //   }
          // },
          {
            path: 'activities-log',
            name: 'serviceActivitiesLog',
            component: () => import(/* webpackChunkName: "serviceActivitiesLog" */ '../../views/admin/services/servicesList/service/activitiesLog/serviceActivitiesLog.vue'),
            meta: {
              permission: 'activity_logs.show',
              pageTitle: 'لاگ های خدمت',
              pageTitleI18n: 'services.service.logs.title'
              // rule: 'editor'
            }
          },
          {
            path: 'edit',
            name: 'EditService',
            component: () => import(/* webpackChunkName: "EditProduct" */ '../../views/admin/services/servicesList/edit/editService.vue'),
            meta: {
              pageTitle: 'ویرایش خدمت',
              pageTitleI18n: 'services.edit.title'
              // rule: 'editor'
            }
          }
        ]
      },
      {
        path: 'insert',
        name: 'InsertService',
        component: () => import(/* webpackChunkName: "InsertService" */ '../../views/admin/services/servicesList/insert/insertService.vue'),
        meta: {
          pageTitle: 'افزودن خدمت',
          pageTitleI18n: 'services.insert.title'
          // rule: 'editor'
        }
      }
    ]
  }
]
}
