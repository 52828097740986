import Vue from 'vue'
import store from '@/store/store'
import {getProfile} from '@/http/requests/profile'
import { loading } from '@/prototypes/u/loading'
import { checkPermissions, getAssetsUrl } from '@/prototypes/u/other'
import { cookie } from '@/prototypes/u/cookie'
import {getCompanyInfo} from "../http/requests/auth";

function validateUser () {
  return getProfile()
}


const $t = sign => Vue.prototype.i18n.t(sign)

function getCompanyInformation () {
  getCompanyInfo().then((response) => {
    const company = response.data
    const clubInfo = {
      clubName: company.company_name,
      clubLogo: company.company_avatar ? getAssetsUrl(company.company_avatar) : ''
    }
    store.dispatch('setting/setClubInfo', clubInfo)
  })
}

function checkPageChange (next) {
  if (store.state.pageHaveChanges) {
    const answer =
      window.confirm($t('alert.unsavedChanges'));

    if (answer) {
      loading.show()
      store.dispatch('removePageChanges')
      next();
    } else {
      loading.hide()
      next(false);
    }
  } else {
    loading.show()
    next()
  }
}

export async function beforeEach (to, from, next) {
  /* in this place check user was logged in or not */

  try {
    store.dispatch('helper/changePrevRoute', from)
    store.dispatch('updateContentNavbarClass', '')
    store.dispatch('setPageTitle', '')
    const accessToken = localStorage.getItem('accessToken') || cookie.get('accessToken')
    if (accessToken) {
      await store.dispatch('auth/setAccessToken').then(() => {
        if (to.name === 'page-login') {
          next({name: 'home'})
        }
        if (from.name === null || from.name === 'page-login') {
          validateUser().then((validate) => {
            if (validate.status === 200) {
              store.dispatch('auth/updateUserInfo', validate.data.data)

              const userInfo = store.state.auth.user
              if (userInfo.active_sessions > userInfo.role.allowed_active_sessions && to.name !== 'Profile' && to.name !== 'profileActiveSessions') {
                next({name: store.getters.isMobile ? 'profileActiveSessions' : 'Profile'})
                loading.hide()
              }

              let tableSettings = JSON.parse(validate.data.data.browser_setting)
              // delete tableSettings.tables.payload
              if (tableSettings) {
                store.dispatch('table/setUserBrowserSettings', tableSettings)
              }

              if (to.meta.hasOwnProperty('permission')) {
                if (checkPermissions(to.meta.permission)) {
                  checkPageChange(next)
                  // next()
                } else {
                  next({name: 'page-error-404'})
                }
              } else {
                checkPageChange(next)
              }
            } else {
              throw 'auth-error'
            }
          })
        } else {
          const userInfo = store.state.auth.user
          if (userInfo.active_sessions > userInfo.role.allowed_active_sessions && to.name !== 'Profile' && to.name !== 'profileActiveSessions') {
            next({name: store.getters.isMobile ? 'profileActiveSessions' : 'Profile'})
            loading.hide()
          }

          if (to.meta.hasOwnProperty('permission')) {
            if (checkPermissions(to.meta.permission)) {
              checkPageChange(next)
            } else {
              next({name: 'page-error-404'})
            }
          } else {
            checkPageChange(next)
          }
        }
      })
    } else if (!to.matched.some(record => record.name === 'page-login')) {
      throw 'auth-error'
    } else {
      next()
    }
  } catch (e) {
    if (!to.matched.some(record => record.name === 'page-login')) {
      next({
        name: 'page-login'
        /* query: {redirectFrom: to.fullPath}*/
      })
      // next(false)
    } else {
      next()
    }
  }
}
