function getCookie(name) {
  let cookie = {}
  document.cookie.split(';').forEach(function(el) {
    let [k,v] = el.split('=')
    cookie[k.trim()] = v
  })
  return cookie[name]
}

function deleteCookie(name) {
  document.cookie = `${name}=; Max-Age=-99999999;`
}

export const cookie = {
  get: getCookie,
  delete: deleteCookie
}
