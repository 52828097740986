// =============================================================================
// REPORTS PAGES LAYOUTS
// =============================================================================

export default {
  path: '/reports',
    component: () => import('../../views/admin/reports/index'),
  children: [
  {
    path: 'activities-log',
    name: 'activitiesLog',
    component: () => import(/* webpackChunkName: "activitiesLog" */ '../../views/admin/reports/activitiesLog/usersActivitiesLog.vue'),
    meta: {
      permission: 'activity_logs.show',
      pageTitle: '',
      pageTitleI18n: 'reports.logs.list.title'
      // rule: 'editor'
    }
  },
  {
    path: 'sale',
    name: 'saleReport',
    component: () => import(/* webpackChunkName: "saleReport" */ '../../views/admin/reports/sale/index.vue'),
    meta: {
      permission: 'activity_logs.show',
      pageTitle: '',
      // pageTitleI18n: 'reports.logs.list.title'
      // rule: 'editor'
    }
  },
  {
    path: 'users-total-sales',
    name: 'usersTotalSalesReport',
    component: () => import(/* webpackChunkName: "usersTotalSalesReport" */ '../../views/admin/reports/usersTotalSales/usersTotalSalesReport.vue'),
    meta: {
      pageTitle: '',
      pageTitleI18n: 'reports.usersTotalSales.title'
      // rule: 'editor'
    }
  }
]
}
