<template>
  <vx-card class="insert-product top-zero-radius m-0 px-3 main-box md:w-3/4">

    <!-- type selector -->
    <vs-prompt
      color="danger"
      :buttons-hidden="true"
      :title="$t('products.insert.types.label')"
      @close="selectInsertTypeStatusPrompt = true, showSelectInsertType()"
      :active.sync="selectInsertTypeStatusPrompt">

      <!--      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
              <vs-row>
                <vs-spacer/>

                <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                  انتخاب نوع افزودن
                </vs-col>

                <vs-spacer/>
              </vs-row>
            </div>-->

      <div class="prompt-content p-3">
        <div class="select-type-box">

          <!-- insert into woocommerce -->
          <button v-for="(shop, shop_index) in shops"
                  :key="shop_index"
                  class="select-type-button woocommerce"
                  @click="selectedInsertType = shop.id, selectInsertTypeStatusPrompt = false">
            <custom-icon icon="WOOCOMMERCE_TEXT"
                         width="50px"
                         height="50px"
                         color-code="#fff"/>

            <vs-spacer/>

            <span class="ml-2">
            {{ $t('products.insert.types.woocommerce', {shopName: shop.name}) }}
          </span>

            <vs-spacer/>
          </button>
        </div>
      </div>
    </vs-prompt>
    <!-- /type selector -->

<!--    <useral-insert-product v-if="selectedInsertType === 'USERAL'"/>-->

    <woocommerce-insert-product v-if="selectedInsertType !== null && selectedInsertType !== 'USERAL'"
                                :in-modal="inModal"
                                :shop-id="selectedInsertType"/>

  </vx-card>
</template>

<script>

import {getShops} from '../../../../../http/requests/plugins/wooreceiver'
import CustomIcon from '../../../../../components/customIcon/customIcon'

export default {
  name: 'insertProduct',
  components: {
    CustomIcon,
    WoocommerceInsertProduct: () => import('./WoocommerceInsertProduct'),
    UseralInsertProduct: () => import('./UseralInsertProduct')
  },
  props: {
    inModal: {
      type: Boolean,
      default: false
    }
  },
  metaInfo () {
    return {
      title: this.$t('products.insert.title')
    }
  },
  data () {
    return {
      selectInsertTypeStatusPrompt: false,
      showErrorNotificationTimer: 0,
      shops: [],
      selectedInsertType: null
    }
  },
  beforeCreate () {
    getShops(1, {status: '3'}).then(response => {
      const shops = response.data.data

      this.shops = shops

      if (this.shops.length > 1) {
        this.selectInsertTypeStatusPrompt = true
      } else if (this.shops.length === 1) {
        this.selectedInsertType = this.shops[0].id
      } else {
        this.selectedInsertType = 'USERAL'
      }
    }).catch(() => {
      this.selectedInsertType = 'USERAL'
    })
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('auth/setAccessToken')
      // this.$store.dispatch('updateNavbarActions', this.actions[0])
      if (!this.inModal) {
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-3/4 m-0 mx-auto')
      }
    }, 50)
  },
  methods: {
    showSelectInsertType () {
      clearTimeout(this.showErrorNotificationTimer)
      this.showErrorNotificationTimer = setTimeout(() => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('products.insert.notifications.type.notSelected.error'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      }, 1000)
    }
  },
  watch: {
    selectedInsertType: {
      handler (val) {
        if (val === 'USERAL') {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('products.insert.notifications.notConnectToServer.error'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          this.$router.replace({name: 'ProductsList'})
        }
      }
    }
  }
}
</script>

<style lang="scss">

.insert-product {
  height: 100%;
}
</style>
