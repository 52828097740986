// =============================================================================
// TICKETS PAGES LAYOUTS
// =============================================================================

export default {
  path: '/ticketing',
    component: () => import('../../views/admin/tickets/index.vue'),
  children: [
  {
    path: 'organizational',
    component: () => import('../../views/admin/tickets/organizationTicket/index.vue'),
    children: [
      {
        path: '/',
        name: 'organizationTickets',
        component: () => import(/* webpackChunkName: "organizationTickets" */ '../../views/admin/tickets/organizationTicket/list/ticketsOrganizationList.vue'),
        meta: {
          permission: 'organizational_ticket.show',
          pageTitle: 'تیکت ها',
          pageTitleI18n: 'tickets.organization.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertOrganizationTicket',
        component: () => import(/* webpackChunkName: "insertOrganizationTicket" */ '../../views/admin/tickets/organizationTicket/insert/insertOrganizationTicket.vue'),
        meta: {
          permission: 'organizational_ticket.create',
          pageTitle: 'افزودن تیکت جدید',
          pageTitleI18n: 'tickets.organization.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id',
        name: 'organizationTicketDetail',
        component: () => import(/* webpackChunkName: "organizationTicketDetail" */ '../../views/admin/tickets/organizationTicket/profile/ticketOrganizationDetail.vue'),
        meta: {
          permission: 'organizational_ticket.show',
          pageTitle: '',
          pageTitleI18n: 'tickets.organization.detail.title'
          // rule: 'editor'
        }
      }
    ]
  }
]
}
