// =============================================================================
// ACCOUNTANCY PAGES LAYOUTS
// =============================================================================

export default {
  path: '/accountancy',
    component: () => import('../../views/admin/accountancy/index.vue'),
  children: [
  {
    path: 'types',
    name: 'documentsType',
    component: () => import(/* webpackChunkName: "documentsType" */ '../../views/admin/accountancy/types/documentsType.vue'),
    meta: {
      permission: 'account.access',
      pageTitle: '',
      pageTitleI18n: 'accountancy.types.title'
      // rule: 'editor'
    }
  },
  {
    path: 'documents',
    component: () => import('../../views/admin/accountancy/documents/index.vue'),
    children: [
      {
        path: '',
        name: 'documents',
        component: () => import(/* webpackChunkName: "documents" */ '../../views/admin/accountancy/documents/list/documentsList.vue'),
        meta: {
          permission: 'document.access',
          pageTitle: '',
          pageTitleI18n: 'accountancy.documents.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertDocument',
        component: () => import(/* webpackChunkName: "insertDocument" */ '../../views/admin/accountancy/documents/insert/insertDocument.vue'),
        meta: {
          permission: 'document.access',
          pageTitle: 'افزودن سند دریافت/پرداخت',
          pageTitleI18n: 'accountancy.documents.insert.dynamicTitle'
          // rule: 'editor'
        }
      },
      {
        path: 'doc-:id',
        name: 'document',
        component: () => import(/* webpackChunkName: "document" */ '../../views/admin/accountancy/documents/document/document.vue'),
        meta: {
          permission: 'document.access',
          pageTitle: '',
          pageTitleI18n: 'accountancy.documents.document.title'
          // rule: 'editor'
        }
      }
    ]
  },
  {
    path: 'opening-balance',
    name: 'openingBalance',
    component: () => import(/* webpackChunkName: "openingBalance" */ '../../views/admin/accountancy/openingBalance/index.vue'),
    meta: {
      permission: 'opening.access',
      pageTitle: '',
      pageTitleI18n: 'accountancy.openingBalance.title'
      // rule: 'editor'
    }
  }
]
}
