<template>
  <vx-card class="edit-product m-0" :class="{'main-box top-zero-radius pt-2 px-3': productId === 0, 'no-box-shadow': productId > 0}">

    <useral-edit-product v-if="selectedIEditType === 'USERAL'"
                         :in-modal="inModal"
                         :product-id="productId || this.$route.params.id"
                         @edited="$emit('edited')"/>

    <woocommerce-edit-product v-else-if="selectedIEditType !== null"
                              :in-modal="inModal"
                              :shop-id="selectedIEditType"
                              :product-id="this.receivedId"
                              @edited="$emit('edited')"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import {getProductStatus} from '../../../../../http/requests/products'

export default {
  name: 'editProduct',
  metaInfo () {
    return {
      title: this.$t('products.edit.title')
    }
  },
  components: {
    CustomIcon,
    UseralEditProduct: () => import('./UseralEditProduct'),
    WoocommerceEditProduct: () => import('./WoocommerceEditProduct')
  },
  data () {
    return {
      selectEditTypeStatusPrompt: false,
      showErrorNotificationTimer: 0,
      selectedIEditType: null,
      receivedId: 0
    }
  },
  props: {
    productId: {
      type: Number,
      default: 0,
      validator (value) {
        return parseInt(value || 0) >= 0
      }
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.$nextTick(() => {
      this.$u.loading.show()
    })
    this.$store.dispatch('setPageTitle', this.$t('products.edit.title'))

    this.checkStatus()
  },
  methods: {
    checkStatus () {
      getProductStatus(this.productId || this.$route.params.id).then(response => {
        const status = response.data.data
        if (status.received && status.received.shop && status.received.shop.id && status.received.synced) {
          this.selectedIEditType = status.received.shop.id
          this.receivedId = status.received.received_id
        } else {
          this.selectedIEditType = 'USERAL'
        }
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    }
  }
}
</script>

<style lang="scss">
.vx-card__body {
  padding: 0 !important;
}

.edit-product {
  height: 100%;
}
</style>
